import { ReactComponent as XIcon } from "../assets/svg/akar-icons_x-fill.svg";
import { ReactComponent as DiscordIcon } from "../assets/svg/akar-icons_discord-fill.svg";
import { ReactComponent as LinkedInIcon } from "../assets/svg/akar-icons_linkedin-box-fill.svg";

export const socials = [
    {
        social: "LinkedInIcon",
        link: "https://linkedin.com/in/abdulalim-ladan",
        icon: <LinkedInIcon className="w-4 h-4 p-0 m-0 self-center block" />,
    },
    {
        social: "twitter",
        link: "https://x.com/_abdlim",
        icon: <XIcon className="w-4 h-4 p-0 m-0 self-center block" />,
    },
    {
        social: "github",
        link: "https://github.com/AbdLim",
        icon: <DiscordIcon className="w-4 h-4 p-0 m-0 self-center block" />,
    },
];
