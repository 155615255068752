export const features = [
    {
        feature: "Mock API responses",
        description:
            "Lorem ipsum is the best placeholder text i can think of putting here until i decide what actually goes here... wee",
        icon: "",
    },
    {
        feature: "Streamline development",
        description:
            "Lorem ipsum is the best placeholder text i can think of putting here until i decide what actually goes here... wee",
        icon: "",
    },
    {
        feature: "Simulate API errors",
        description:
            "Lorem ipsum is the best placeholder text i can think of putting here until i decide what actually goes here... wee",
        icon: "",
    },
];
