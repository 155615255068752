import { Outlet, Route, Routes } from "react-router-dom";
import Home from "../pages/landing";
import Privacy from "../pages/landing/Privacy";
import Terms from "../pages/landing/Terms";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";

const WithNavAndFooter = () => (
    <>
        <NavBar />
        <div className="max-w-screen-lg pt-20 pb-40 antialiased scroll-smooth mx-auto">
            <Outlet />
        </div>
        <Footer />
    </>
);

const Router = () => {
    return (
        <Routes>
            {/* Routes with landing page navbar */}
            <Route element={<WithNavAndFooter />}>
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/privacy-policy"
                    element={<Privacy />}
                />
                <Route
                    path="/terms"
                    element={<Terms />}
                />
            </Route>
        </Routes>
    );
};

export default Router;
