import { Link } from "react-router-dom";
import LogoName from "../assets/image/logoname.png";
import StyledButton from "./styled/StyledButton";

const NavBar = () => {
    return (
        <nav className="w-full px-14 py-4 flex justify-between bg-background border-b border-card-border shadow-md z-10 fixed">
            <div>
                <Link to={"/"}>
                    <img
                        src={LogoName}
                        alt="applogo"
                        className="w-24"
                    />
                </Link>
            </div>
            <div>
                <StyledButton
                    variant="secondary"
                    children={"Join the waitlist"}
                />
            </div>
        </nav>
    );
};
export default NavBar;
