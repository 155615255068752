import Features from "./component/Features";
import Hero from "./component/Hero";
import Screenshot from "./component/Screenshot";

const Home = () => {
    return (
        <div className="flex flex-col gap-8">
            <Hero />
            <Screenshot />
            <Features />
        </div>
    );
};
export default Home;
