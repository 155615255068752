import { useEffect, useState } from "react";
import MarkdownDisplay from "../../components/MarkdownDisplay";

const Privacy = () => {
    const [markdownContent, setMarkdownContent] = useState("");

    useEffect(() => {
        fetch("/privacy.md")
            .then((response) => response.text())
            .then((content) => setMarkdownContent(content));
    });

    return (
        <div className=" break-words">
            <MarkdownDisplay markdown={markdownContent} />
        </div>
    );
};
export default Privacy;
