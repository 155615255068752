import React from "react";
import { Link, LinkProps } from "react-router-dom";

type StyledButtonProps = {
    variant?: "primary" | "secondary";
    size?: "small" | "medium" | "large";
    className?: string;
    to?: string;
    onClick?: () => void;
    disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const buttonBaseStyles = `
  inline-flex
  items-center
  justify-center
  rounded
  font-medium
  transition
  ease-linear
  focus:outline-none
  focus:ring-2
  focus:ring-offset-2
  cursor-pointer
`;

const sizeStyles = {
    small: "px-3 py-2 text-xs",
    medium: "px-3 py-2 text-sm",
    large: "px-6 py-3 text-base",
};

const variantStyles = {
    primary: `
    bg-primary
    text-background
    hover:bg-primary-btn-hovered
  `,
    secondary: `
    border
    border-primary
    bg-primary/15
    text-primary
    hover:bg-primary-btn-hovered
    hover:text-background
  `,
};

const StyledButton: React.FC<StyledButtonProps> = ({
    variant = "primary",
    size = "small",
    to,
    onClick,
    disabled,
    children,
    className,
    ...rest
}) => {
    const classes = `
    ${buttonBaseStyles}
    ${sizeStyles[size]}
    ${variantStyles[variant]}
    ${disabled ? "opacity-50 cursor-not-allowed" : ""}
    ${className}
  `;

    if (to) {
        const linkProps: LinkProps = {
            to,
            className: classes,
            ...rest,
        } as LinkProps;

        return <Link {...linkProps}>{children}</Link>;
    }

    return (
        <button
            onClick={onClick}
            className={classes}
            disabled={disabled}
            {...rest}
        >
            {children}
        </button>
    );
};

export default StyledButton;
