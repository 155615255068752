import { features } from "../../../data/features";

const Features = () => {
    return (
        <div className="flex flex-row gap-5">
            {features.map((feature, index) => (
                <div className="p-3 flex-1 flex flex-col rounded-md bg-card border-2 border-transparent hover:border-card-border transition ease-linear">
                    <div className="flex flex-row gap-3 pb-3 mb-3 border-b border-card-border">
                        <img
                            className="p-2 rounded-full border border-primary"
                            src={feature.icon}
                            alt=""
                        />
                        <p className="font-serif text-white/50">
                            {feature.feature}
                        </p>
                    </div>
                    <p>{feature.description}</p>
                </div>
            ))}
        </div>
    );
};
export default Features;
