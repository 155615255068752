import StyledButton from "../../../components/styled/StyledButton";

const Hero = () => {
    return (
        <div className="flex flex-wrap gap-8 pt-20 mb-10">
            <div className="flex-1 flex flex-col gap-4">
                <div>
                    <span className="text-xs font-serif font-medium bg-primary/15 px-4 py-2 rounded-md border border-primary">
                        Welcome to Mockraft
                    </span>
                </div>
                <h1 className="leading-tight">
                    Streamlining development one mock at a time
                    <span className="text-primary">_</span>
                </h1>
            </div>
            <div className="flex-1 flex flex-col gap-4 pt-10">
                <p className="text-white/50 font-serif">
                    Craft your backend seamlessly with Mockraft’s powerful mock
                    APIs. Accelerate frontend development, streamline backend
                    planning, and set up real-time responses with ease—using the
                    tools and languages you love.
                </p>
                <div>
                    <StyledButton children={"Join the waitlist"} />
                </div>
            </div>
        </div>
    );
};
export default Hero;
