import { Link } from "react-router-dom";
import { socials } from "../data/socials";

const Footer = () => {
    return (
        <footer className="w-full px-14 py-4 flex justify-between border-t border-card-border">
            <div className="flex flex-row gap-3 text-white/50">
                <p className="text-xs text-white/50">
                    &copy; Mockraft 2024. All rights reserved
                </p>
                <Link
                    className="text-xs underline"
                    to="/privacy-policy"
                    children={"Policy"}
                />
                <Link
                    className="text-xs underline"
                    to="/terms"
                    children={"Terms"}
                />
            </div>
            <div>
                <ul className="list-none flex">
                    {socials.map((social, index) => (
                        <li key={index}>
                            <Link
                                to={social.link}
                                target="_blank"
                                className="flex p-2 rounded bg-button hover:bg-white/15 transition-colors ease-linear"
                            >
                                {social.icon}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </footer>
    );
};
export default Footer;
