import ReactMarkdown from "react-markdown";

const MarkdownDisplay = ({ markdown }: { markdown: string }) => {
    return (
        <ReactMarkdown className="whitespace-break-spaces">
            {markdown}
        </ReactMarkdown>
    );
};
export default MarkdownDisplay;
